<template>
  <div class="add-project">
    <ContentHeader :header_title="headerTitle" back><span></span></ContentHeader>
    <div class="add-project-steps-wrap">
      <Steps :current="current" :status="currentStatus">
        <Step title="基本信息配置"></Step>
        <Step title="Analysis配置（选填）"></Step>
        <Step title="GooglePlay配置（选填）"></Step>
      </Steps>
      <div class="step-wrap">
        <div
          class="step-form"
          :style="{ transform: `translateX(${-100 * current}%) translateZ(0px)` }"
        >
          <div class="step-pane"
               v-for="item in [0,1,2]"
               :key="item"
               :style="{ visibility:current === item ? 'visible':'hidden' }">
            <template v-if="item===0">
              <Form ref="projectForm"
                    :rules="projectFormRules"
                    :model="projectForm"
                    @on-validate="handleValidate"
                    @submit.native.prevent>
                <ProjectFormItem :form="projectForm"></ProjectFormItem>
              </Form>
            </template>
            <template v-else-if="item===1">
              <Form ref="analysisForm"
                    :rules="analysisFormRules"
                    :model="analysisForm"
                    @on-validate="handleValidate">
                <AnalysisFormItem :form="analysisForm"></AnalysisFormItem>
              </Form>
            </template>
            <template v-else-if="item===2">
              <Form ref="googlePlayForm"
                    :rules="googlePlayFormRules"
                    :model="googlePlayForm"
                    @on-validate="handleValidate">
                <GooglePlayFormItem :form="googlePlayForm"></GooglePlayFormItem>
              </Form>
            </template>
          </div>
        </div>
      </div>
      <div class="step-button">
        <Button @click="handlePrevStep" :disabled="current===0">上一步</Button>
        <Button @click="handleNextStep" :disabled="current===2">下一步</Button>
        <Button type="primary" @click="handleSubmit">确认</Button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import ContentHeader from '@/components/ContentHeader.vue';
import ProjectFormItem from '@/views/home/content/config/ProjectFormItem.vue';
import AnalysisFormItem from '@/views/home/content/config/AnalysisFormItem.vue';
import GooglePlayFormItem from '@/views/home/content/config/GooglePlayFormItem.vue';
import formMixin from '@/views/home/content/config/formMixin';

export default {
  name: 'ProjectCreate',
  components: {
    GooglePlayFormItem, AnalysisFormItem, ProjectFormItem, ContentHeader,
  },
  mixins: [formMixin],
  data() {
    return {
      current: 0,
      headerTitle: '添加项目',
      currentStatus: 'process',
      allProjectNames: localStorage.getItem('projectNames').split(','),
      isSubmit: false,
    };
  },
  computed: {
    currentForm() {
      const forms = ['projectForm', 'analysisForm', 'googlePlayForm'];
      return this.$refs[forms[this.current]][0];
    },
  },
  watch: {
    analysisForm: {
      deep: true,
      handler(newVal) {
        if (this.isEmptyForm(newVal)) {
          this.currentForm.resetFields();
        }
      },
    },
    googlePlayForm: {
      deep: true,
      handler(newVal) {
        if (this.isEmptyForm(newVal)) {
          this.currentForm.resetFields();
        }
      },
    },
  },
  beforeRouteLeave(to, from, next) {
    const showLeaveConfirm = this.projectForm.pid
      || this.projectForm.project_name || this.projectForm.project_logo;
    if (!this.isSubmit && showLeaveConfirm) {
      this.$Modal.confirm({
        title: '确认离开当前页面吗？',
        onCancel: () => {
          next(false);
        },
        onOk: () => {
          next();
        },
      });
    } else {
      next();
    }
  },
  methods: {
    handlePrevStep() {
      if (this.current > 0) {
        this.current -= 1;
      }
    },
    handleNextStep() {
      if (this.isFormValid()) {
        this.current += 1;
      } else {
        this.currentForm.validate();
      }
    },
    handleValidate(prop, status) {
      if (this.isFormValid()) {
        this.currentStatus = 'process';
      } else if (!status) {
        this.currentStatus = 'error';
      }
    },
    async submitForm() {
      try {
        const formData = new FormData();
        const { project_logo: projectLogo, ...projectInfo } = this.projectForm;
        formData.append('project_info', JSON.stringify(projectInfo));
        formData.append('project_logo', projectLogo);

        if (this.analysisForm.analysis_service) {
          const { analysis_service: analysisService, ...analysisInfo } = this.analysisForm;
          formData.append('analysis_info', JSON.stringify(analysisInfo));
          formData.append('analysis_service', analysisService);
        }

        if (this.googlePlayForm.google_play_service) {
          const { google_play_service: googlePlayService, ...googlePlayInfo } = this.googlePlayForm;
          formData.append('google_play_info', JSON.stringify(googlePlayInfo));
          formData.append('google_play_service', googlePlayService);
        }

        const { data } = await this.$api.createProjectAndConfig(formData);
        if (data.result === 'success') {
          this.$Message.success('项目创建成功');
          this.isSubmit = true;
          this.$router.back();
        } else {
          this.$Message.error('项目创建失败');
        }
      } catch (error) {
        this.$Message.error('项目创建失败');
        console.log(error);
      }
    },
    handleSubmit() {
      if (this.isFormValid()) {
        this.submitForm();
      } else {
        this.currentForm.validate();
      }
    },
    isFormValid() {
      if (this.current === 0) {
        const projectName = this.projectForm.project_name;
        return this.projectForm.pid && projectName && !this.allProjectNames.includes(projectName);
      }
      if (this.current === 1) {
        return this.isFormEmptyOrFilled(this.analysisForm);
      }
      return this.isFormEmptyOrFilled(this.googlePlayForm);
    },
    // 表单是否全部填写或者全部未填写
    isFormEmptyOrFilled(form) {
      const set = new Set();
      for (const key of Object.keys(form)) {
        set.add(!!form[key]);
      }
      return set.size === 1;
    },
    // 表单所有字段都未填写
    isEmptyForm(form) {
      for (const key of Object.keys(form)) {
        if (form[key]) return false;
      }
      return true;
    },
  },
};
</script>

<style scoped lang="less">
@messageTextMarginBottom: 9px;
@footerBtnHeight: 36px;
.add-project {
  padding: 24px;
  .add-project-steps-wrap {
    background: #fff;
    margin-top: 24px;
    padding: 24px;
    border-radius: 12px;
    .step-wrap {
      margin: 24px;
      overflow: hidden;
      .step-form{
        display: flex;
        flex-direction: row;
        will-change: transform;
        transition: transform 0.3s ease-in-out;
      }
      .step-pane{
        width: 100%;
        flex-shrink: 0;
      }
      /deep/ .ivu-form-label-left .ivu-form-item-label {
        text-align: right;
      }
    }
    .step-button {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
      button {
        margin: 0 5px;
        &.ivu-btn-default[disabled]{
          color: rgba(96, 111, 247, 0.6);
          border-color: rgba(96, 111, 247, 0.6);
          background: #fff;
        }
      }
    }
  }
}
</style>
